<template>
  <el-dialog
    :title="`${title}监控视频`"
    :visible.sync="videoVisible"
    width="40%"
    :lock-scroll="false"
    @before-close="beforeClose"
  >

    <video
      :src="url === 'scdisinfection.terabits.cn'?`http://${url}${videoAddress.replace('D:', '').replace('\\', '/')}`:`http://${url}${videoAddress}`"
      id="myvideoback"
      width="100%"
      height="100%"
      controls
      autoPlay
      muted
    >
    </video>
  </el-dialog>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      videoVisible: false,
      title: '',
      videoAddress: ''
    };
  },
  created () {

  },
  mounted () {

  },
  computed: {
    ...mapGetters(['url']),
  },
  methods: {
    init (val, siteName, roomName) {
      // console.log(val)
      this.title = siteName + roomName
      this.videoAddress = val
      this.videoVisible = true
    },
    beforeClose () {
      this.videoAddress = ''
    }
  }
};
</script>

<style scoped lang="scss">
</style>
 