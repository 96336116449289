<template>
  <el-dialog title="抓拍详情" :visible.sync="workingVisible" width="35%" top="5vh" :lock-scroll="false">
    <div class="axis">
      <el-row>
        <el-col :span="3" v-for="(item,index) in timeList" :key="index">
          <div class="default">
            <span></span>
            {{item}}
          </div>
        </el-col>
      </el-row>
      <span>
        24时
      </span>
      <div class="scope">
        <el-tooltip class="item" v-for="(item,index) in timePiarList" :key="index" effect="dark" :content="item.time" placement="top-start">
          <span :style="{left:item.start,width:item.width}"></span>
        </el-tooltip>

      </div>
    </div>
    <div class="legend">
      <p>摄像头抓拍时间</p>
      <span></span>
    </div>
    <h3>
      摄像头抓拍时长: {{totalTime}} 分
    </h3>
    <div class="working">
      <div class="tablebox">
        <base-table ref="myTable" :columns="columns" :tableList="tableData"></base-table>
      </div>
      <base-pagination class="basepagination" v-show="total>10" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" :layout="layout" @pagination="getList"></base-pagination>
    </div>
    <el-dialog width="30%" :title="roomName" :visible.sync="innerVisible" append-to-body :lock-scroll="false">
      <div class="workingimg">
        <img style="width:100%,height:100%" :src="url === 'scdisinfection.terabits.cn'?`http://${url}${innerImg.replace('D:', '').replace('\\', '/')}`:`http://${url}${innerImg}`" alt="">
      </div>
    </el-dialog>

  </el-dialog>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      workingVisible: false,
      innerVisible: false,
      timeList: ['0时', '3时', '6时', '9时', '12时', '15时', '18时', '21时'],
      timePiarList: [],
      totalTime: 0,
      columns: [
        { attrs: { prop: 'start', label: '开始时间', align: 'center' } },
        { attrs: { prop: 'end', label: '结束时间', align: 'center' } },
        {
          attrs: { label: '截取图片', align: 'center' },
          btns: row => [
            { name: '查看', attrs: { type: 'text' }, listeners: { click: () => this.viewImg(row) } }
          ]
        }
      ],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
      totalList: [],
      layout: 'prev, pager, next',
      roomName: '',
      innerImg: ''
    };
  },
  created () {

  },
  mounted () {

  },
  computed: {
    ...mapGetters(['url'])
  },
  methods: {
    init (val, siteName, roomName) {
      console.log(val)
      this.workingVisible = true
      this.totalList = val.timePiarList
      this.roomName = siteName + roomName
      this.timePiarList = this.disposeData(val.timePiarList)
      this.totalTime = val.grabTotalTime
      this.total = this.totalList.length
      this.getList()
    },
    disposeData (val) {
      // console.log(val)
      const newArr = []
      const oneDay = 864
      val.map(el => {
        newArr.push({
          start: `${(this.$moment.duration(this.$moment(el.start).format('HH:mm:ss')).as('seconds') / oneDay).toFixed(2)}%`,
          width: `${(this.$moment.duration(this.$moment(el.end).format('HH:mm:ss')).as('seconds') / oneDay - this.$moment.duration(this.$moment(el.start).format('HH:mm:ss')).as('seconds') / oneDay).toFixed(2)}%`,
          time: `${this.$moment(el.start).format('HH:mm:ss')} ~ ${this.$moment(el.end).format('HH:mm:ss')}`
        })

      })
      // console.log(newArr)
      return newArr
    },
    viewImg (val) {
      console.log(val)
      this.innerImg = val.filePath
      this.innerVisible = true
    },
    getList () {
      this.tableData = []
      var start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      var end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }

      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
    },
  }
};
</script>

<style scoped lang="scss">
.axis {
  position: relative;
  .el-row {
    .el-col-3 {
      .default {
        span {
          display: inline-block;
          width: 100%;
          height: 0.02rem;
          border-bottom: 2px solid #999;
          border-left: 2px solid #999;
        }
      }
    }
    .el-col-3:last-child {
      .default {
        span {
          border-right: 2px solid #999;
        }
      }
    }
  }
  > span {
    position: absolute;
    bottom: 0;
    right: -2%;
  }
  .scope {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    > span {
      position: absolute;
      display: block;
      height: 40%;
      background: rgb(19, 157, 244);
    }
  }
}
.legend {
  justify-content: center;
  margin: 20px 0;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    margin-left: 10px;
    width: 12px;
    height: 12px;
    background: rgb(19, 157, 244);
  }
}
.working {
  .tablebox {
    padding: 0;
    .base-table-container {
      margin: 0;
      margin-top: 10px;
      ::v-deep .table-wrapper {
        height: 100%;
        .el-table--enable-row-hover
          .el-table__body
          tr:hover
          > td.el-table__cell {
          background: none !important;
        }
      }
    }
  }
  ::v-deep .el-table tr {
    color: #999;
    font-weight: bold;
  }
  ::v-deep .el-table tr:nth-of-type(2n) {
    background: none;
  }

  .basepagination {
    text-align: right;
  }
}
.workingimg {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
 